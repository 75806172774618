// router.js 或 router/index.js
import { createRouter, createWebHistory } from "vue-router";
import ServerManageRoutes from "./modules/server-manage/routes.js";
import GeekInsightRoutes from "./modules/geek-insight/routes.js";
import JustCoaxRoutes from "./modules/just-coax/routes.js";
import HyperHubRoutes from "./modules/hyper-hub/routes.js";
import MikuSearchRoutes from "./modules/miku-search/routes.js";
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/login",
  },
  {
    path: "/index",
    name: "Index",
    redirect: '/miku-search/miku-index',
    component: () => import("@/pages/index.vue"),
    meta: {
      isAuth: true
    },
    children: [
      // 更多路由规则...
      ...ServerManageRoutes,
      ...HyperHubRoutes,
      ...JustCoaxRoutes,
      ...GeekInsightRoutes,
      ...MikuSearchRoutes,
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/login.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/pages/not-found.vue"),
  },
];

console.log(routes);

const router = createRouter({
  history: createWebHistory("/hyper-cloud"),
  routes,
});

router.beforeEach((to, from, next) => {
  const PassWord = 'hyper666'
  const UserName = 'hypercloud'
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  if(to.path !== "/login") {
    if(username === UserName && password === PassWord) return next();
    next('/login');
  }else {
    if(username === UserName && password === PassWord) return next('/index');
    next();
  }
});

export default router;
