<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
#app {
  height: 100vh;
}
</style>
