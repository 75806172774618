// 创建单个路由记录
export function createRoute(record, basePath, prefix) {
  const route = {
    path: `${basePath}${record.path}`,
    name: record.name ? `${prefix}${record.name}` : null,
    component: record.children ? null : () => import(`@/pages${basePath}${record.path}.vue`),
  };
  if (record.redictTo) {
    route.redictTo = record.redictTo;
  }
  if (record.children && record.children.length > 0) {
    route.children = record.children.map(child => createRoute(child, basePath, prefix));
  }
  return route;
}
  
  // 展平路由数组
export function flattenRoutes(routes) {
  const flatRoutes = [];
  routes.forEach(route => {
    flatRoutes.push(route);
    if (route.children) {
      route.children.forEach(childRoute => flatRoutes.push(childRoute));
    }
  });
  return flatRoutes;
}
  
  // 获取路由配置
export function getRoutes(menus, basePath, prefix) {
  const nestedRoutes = menus.map(menu => createRoute(menu, basePath, prefix));
  const flatRoutes = flattenRoutes(nestedRoutes);
  return flatRoutes;
}

export default getRoutes
  
