import menuData from './menus.json';
import getRoutes from '@/uitls/router/getRoutes';
const basePath = '/just-coax';
const prefix = 'JustCoax';

// 使用 getRoutes 函数获取 JustCoax 路由
const JustCoaxRoutes = getRoutes(menuData, basePath, prefix);

// 导出路由配置
export default JustCoaxRoutes;
